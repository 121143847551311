import imagePath from './webP/gift.webp';

const spotlight = {
	"Title": "Geschenkje nodig?",
	"InnerHtml":
		<div>
			<p className='spotlight_text'>
				Wil je iemand verrassen met een fijne attentie? <br/>
				Met een wijnpakket scoor je altijd!
			</p>
		</div>,
	"ImagePath": imagePath
};

export default spotlight;
